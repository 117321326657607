<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Neuer Hutewald</h1>
      <p>
        In Spanien ist eine Dehesa ein Waldtyp, der von Eichen (Quercus ilex, Quercus suber) dominiert wird. Die Dehesa
        ist über Jahrhunderte von Menschenhand geschaffen, indem der Wald und seine Erzeugnisse für viele verschiedene
        landwirtschaftliche Aktivitäten ( einschließlich Viehbeweidung) genutzt wurden. Das iberische Schwein ist ein
        wesentlicher Bestandteil der Dehesa, da es sich während des Herbstes und über den Winter fast ausschließlich von
        Eicheln von den Steineichen ernährt und so fett wird.
      </p>
      <p>
        Leider haben in letzter Zeit Unter- und Überweidung und andere Probleme beginnender <router-link
          :to="{ name: 'desertification' }">
          Wüstenbildung</router-link> geführt und was einst ein hochproduktives Agroforstsystem war, ist jetzt im
        Niedergang begriffen. Erschwerend kommt hinzu, dass dadurch auch die sehr alten Eichen absterben.
      </p>

      <h2>Neue Wege</h2>
      <p>
        Wir haben uns neue Wege einfallen lassen, um die Idee der Dehesa aufrechtzuerhalten und gleichzeitig die
        Fruchtbarkeit im Boden wiederherzustellen.
      </p>
      <p>
        Anstatt die Methode der festen Viehbesetzung (eine feste Anzahl Tiere pro Hektar über einen langen Zeitraum)
        setzen wir auf eine hohe Besatzdichte (viele Tiere auf wenig Raum für kurze Zeit) und ein System
        von Wegen für die Tiere und kleinen Flächen, die im Laufe des Jahres unterschiedliche Rollen spielen.
      </p>

      <picture>
        <source media="(max-width: 500px)" srcset="/img/concept/animalCropPasture.png">
        <img src="/img/concept/animalCropPasture.png" />
      </picture>

      <p>
        Je nach ihren natürlichen Instinkten werden die Tiere den Boden aufreißen (Schweine) oder bei der Futtersuche
        die Kruste an der Oberfläche mit ihren Hufen öffnen. Sie laufen frei umher - nur geleitet durch Wege und
        Tore - und gehen genauso wie wilde Tiere zu einer Tränke.
      </p>
      <p>
        Nachdem Schweine ein Gebiet entwurzelt haben, säen wir dort eine Polykultur an, damit es neues Futter für Rinder
        und Schweine gibt. Sobald es für die Ernte bereit ist, fressen die Rinder die Blätter und die Schweine gehen auf
        die Suche nach den Wurzeln.
      </p>
      <p>
        Dies ist nur ein kurzer Überblick über unser Konzept. Es ist nicht <em>in Stein gemeißelt</em> und entwickelt
        sich, während wir durch Beobachtung lernen. Unser Hauptziel ist es, die Bodenfruchtbarkeit wiederherzustellen,
        um Überfluss zu schaffen, damit wir schließlich den Überschuss ernten können.
      </p>
      <p>
        Zusätzlich zu dem oben genannten fügen wir auch viele neue Pflanzenarten hinzu und pflanzen viele
        zusätzliche Bäume für Schatten und Biomasse sowie als Futter.
      </p>

    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
export default {
  name: "DehesaDE",
  components: {
    Sidebar
  },
};
</script>
